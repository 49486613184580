import classNames from 'classnames';
import { MouseEvent } from 'react';
import { Icon } from '..';
import ButtonStyled from './ButtonStyled';

interface ButtonProps {
  iconName?: string;
  children?: any | string;
  className: string;
  primary?: boolean;
  secondary?: boolean;
  small?: boolean;
  medium?: boolean;
  active?: boolean;
  disabled: boolean;
  title: string | null;
  iconBtnSmall?: boolean;
  rightIcon?: string;
  outlined?: boolean;
  link?: boolean;
  search?: boolean;
  borderless?: boolean;
  big?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement | MouseEvent>) => void | {};
}

const Button = ({
  children,
  className,
  primary,
  secondary,
  outlined,
  small,
  medium,
  active,
  link,
  disabled,
  iconName,
  title,
  iconBtnSmall,
  search,
  borderless,
  rightIcon,
  big,
  onClick,
  ...otherProps
}: ButtonProps) => {
  const classes = classNames(
    {
      primary,
      secondary,
      outlined,
      small,
      medium,
      big,
      active,
      link,
      disabled,
      iconBtnSmall,
      search,
      borderless,
    },
    className,
  );
  return (
    <ButtonStyled className={classes} onClick={onClick} {...otherProps}>
      {iconName ? <Icon name={iconName} iconName={iconName} /> : null}
      {title ? <span>{title}</span> : null}
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  children: undefined,
  className: '',
  primary: false,
  secondary: false,
  small: false,
  active: false,
  disabled: false,
  title: null,
  iconBtnSmall: false,
};

export default Button;
