import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_ROLE_LIST } from '../userRole/API';
import { CHECK_IF_ALREADY_EXIST, DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import { USER_TITLE_FRAGMENT } from '../../../common/GqlFragments';
import { GET_DESIGNATION_LIST } from '../designation/API';

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    roleId
    role: Role {
      name
    }
    lastName
    firstName
    title: fullName
    fullName
    phoneNumber
    parentUserId
    billingStatus
    active
    townIds
    designationId
    rigoDetails{
      rigo_id
      device_id
    }
    ClassificationType {
        id
        title
    }
  }
`;

const GET_USER = gql`
  query getUser($filter: FilterInput) {
    users(filter: $filter) {
      rows {
        ...UserFragment
        Distributor {
          id
          title
        }
        Town {
          id
          title
        }
        billingStatus
        ClassificationType {
          id
          title
        }
        billingCredentials {
          user_id
          username
          password
        }
        townIds
        distributorIds
        UserPrinciple {
        id
        title
      }
      }
    }
  }
  ${USER_FRAGMENT}
`;

const GET_USER_LIST = gql`
  query getUser($limit: Int, $offset: Int, $filter: FilterInput) {
    users(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        ...UserFragment
      }
      count
    }
  }
  ${USER_FRAGMENT}
`;

const GET_FILTERED_USER_LIST = gql`
  query getFilteredUsers( $filter: [UserFilter]) {
  filterUsers (filter: $filter) {
    rows {
      ...UserTitleFragment
    }
  }
}

${USER_TITLE_FRAGMENT}
`

const GET_USER_WITH_TOWN_LIST = gql`
  query getUser($limit: Int, $offset: Int, $filter: FilterInput) {
    users(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        ...UserFragment
        Town {
          id
        }
      }
      count
    }
  }
  ${USER_FRAGMENT}
`;

const GET_CLASSIFICATION_LIST = gql`
  query ClassificationTypes {
    classificationTypes {
    rows {
      id
      title
    }
  }
}
`;

const CREATE_USER = graphql(
  gql`
   mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
    id
    firstName
    lastName
    fullName
    email
    phoneNumber
    roleId  
    parentUserId
    rigoDetails {
          rigo_id
          device_id
        }
  }
}
  `,
  {
    props: ({ mutate }) => ({
      createUser: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_USER = graphql(
  gql`
    mutation (
      $id: Int!
      $firstName: String!
      $lastName: String!
      $email: String
      $phoneNumber: String!
      $roleId: Int!
      $parentUserId: Int
      $billingStatus: Boolean
      $billingCredentials: BillingCredentialsInput
      $townIds: [Int]
      $distributorIds: [Int]
      $classificationTypeId: Int
      $designationId: Int
      $buIds: [Int]
      $rigoDetails: RigoDetailInput
    ) {
      updateUser(
        id: $id
        input: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          roleId: $roleId
          parentUserId: $parentUserId
          billingStatus: $billingStatus
          billingCredentials: $billingCredentials
          townIds: $townIds
          distributorIds: $distributorIds
          classificationTypeId: $classificationTypeId
          designationId: $designationId
          buIds: $buIds
          rigoDetails: $rigoDetails
        }
      ) {
        ...UserFragment
        billingCredentials {
          user_id
          username
        }
        Distributor {
          id
          title
        }
        Town {
          id
          title
        }
        townIds
        distributorIds
        UserPrinciple {
        id
        title
        }
      }
    }
    ${USER_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateUser: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const REPLACE_USER = graphql(
  gql`
    mutation($replace: Int!, $replaceWith: Int!){
      replaceUser(replace: $replace, replaceWith: $replaceWith){
        rows{
          id
          fullName
        }
      }
    }`,
  {
    props: ({ mutate }) => ({
      replaceUser: (variables) => mutate({
        variables
      })
    })
  }
)

const GET_BILLING_USER_LIST = gql`
  query getBillingUsers($distributorId: Int!) {
    billingUsers(distributorId: $distributorId) {
      billingUsers {
        idUsers
        name
        email
      }
    }
  }
`;

const TOGGLE_STATE = graphql(
  gql`
    mutation ($ids: [Int]!, $type: statefulEntities, $active: Boolean!) {
      toggleState(ids: $ids, type: $type, active: $active)
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleState: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const TOGGLE_USER_STATUS = graphql(
  gql`
    mutation ($id: Int!, $active: Boolean!) {
      toggleUser(id: $id, active: $active) {
        firstName
        lastName
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleUser: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const RESET_PASSWORD = graphql(
  gql`
    mutation ($userId: Int!, $password: String!) {
      resetPasswordByAdmin(userId: $userId, password: $password)
    }
  `,
  {
    props: ({ mutate }) => ({
      resetPassword: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_BU_LIST = gql`
    query getCatalogDetail($limit: Int, $offset: Int, $catalogId: Int, $parentCatalogDetailId:Int) {
        catalogDetails(offset: $offset, limit: $limit, catalogId: $catalogId,parentCatalogDetailId: $parentCatalogDetailId) {
            rows {
                id
                title
                parentCatalogDetail:ParentCatalogDetail{
                  id
                  title
                }
                catalogId
                parentCatalogDetailId
                skus:SKUs{
                  id
                  title
                }
            }
            count
        }
    }
`;

const query = {
  getUserList: GET_USER_LIST,
  getUserWithTownList: GET_USER_WITH_TOWN_LIST,
  getUserRoleList: GET_ROLE_LIST,
  getUser: GET_USER,
  getBillingUsers: GET_BILLING_USER_LIST,
  checkIfAlreadyExist: CHECK_IF_ALREADY_EXIST,
  getDesignationList: GET_DESIGNATION_LIST,
  getFilteredUsers: GET_FILTERED_USER_LIST,
  getClassificationList:GET_CLASSIFICATION_LIST,
  getDesignationList: GET_DESIGNATION_LIST,
  getBuList: GET_BU_LIST,
};

const mutation = {
  createUser: CREATE_USER,
  updateUser: UPDATE_USER,
  replaceUser: REPLACE_USER,
  toggleState: TOGGLE_STATE,
  toggleUser: TOGGLE_USER_STATUS,
  resetPassword: RESET_PASSWORD,
  downloadReport: DOWNLOAD_REPORT
};

export {
  CREATE_USER,
  UPDATE_USER,
  REPLACE_USER,
  TOGGLE_STATE,
  GET_USER_LIST,
  GET_BILLING_USER_LIST,
  TOGGLE_USER_STATUS,
  RESET_PASSWORD,
  DOWNLOAD_REPORT,
};

export { query, mutation };
