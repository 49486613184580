import { USER_ACCOUNT } from '../../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const stateToRequestMapper = (request) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    roleId,
    parentUserId,
    billingCredentials,
    billingStatus,
    townIds,
    distributorIds,
    classificationTypeId,
    designationId,
    buIds,
    rigoDetails,
  } = request;
  return {
    email,
    parentUserId,
    billingStatus,
    billingCredentials,
    townIds: townIds.length > 0 ? townIds : [],
    distributorIds: distributorIds.length > 0 ? distributorIds : [],
    classificationTypeId,
    designationId,
    firstName,
    lastName,
    phoneNumber: phoneNumber.toString(),
    password,
    roleId,
    buIds,
    rigoDetails: rigoDetails.rigoId && rigoDetails.deviceId ? {
      rigo_id: rigoDetails.rigoId,
      device_id: rigoDetails.deviceId,
    }:{
      rigo_id: null,
      device_id: null,
    },
};
};

const responseToStateMapper = (response) => {
  const {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    roleId,
    parentUserId,
    billingCredentials,
    billingStatus,
    Distributor,
    Town,
    designationId,
    ClassificationType,
    UserPrinciple,
    rigoDetails,
  } = response;


  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    roleId,
    parentUserId,
    billingCredentials: billingCredentials || {},
    billingStatus,
    townIds: Town ? Town.map((d) => d.id) : null,
    distributorIds: Distributor ? Distributor.map((d) => d.id) : null,
    buIds: UserPrinciple ? UserPrinciple?.map((item) => item?.id) : null,
    Distributor,
    Town,
    designationId,
    classificationTypeId: ClassificationType?ClassificationType[0]?.id : null,
    rigoDetails: {
      rigoId: rigoDetails?.rigo_id || null,
      deviceId: rigoDetails?.device_id || null,
    },
  }
};

const getBaseUserData = (id) => ({
  id,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  roleId: 0,
  designationId:null,
  parentUserId: null,
  billingCredentials: {
    user_id: null,
  },
  townIds: [],
  distributorIds: [],
  billingStatus: false,
  Distributor: [],
  Town: [],
  classificationTypeId:0,
    rigoDetails: {
      rigoId: null,
      deviceId: null,
    },
  });

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'User Account',
    path: `/${USER_ACCOUNT}`,
    active: true,
  },
];

const uniqueFields = {
  EMAIL: 'email',
  PHONE: 'phoneNumber',
};

export {
  uniqueFields, getBaseUserData, stateToRequestMapper, responseToStateMapper, breadCrumb as breadCrumbConfig
};
