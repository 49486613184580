import React from 'react';
import LoadingStyled from './LoadingStyled';

interface LoadingProps {
  children?: any | string;
  classNames: string;
  title: string;
}

const Loading = ({ children, classNames, title, ...otherProps }: LoadingProps) => (
  <>
    <LoadingStyled title={title} {...otherProps} className='loading-main'>
      <div className='loading-wrap'>
        <div className='spinner-wrap'>
          <div className='spinner' />
        </div>
        <p>{title}</p>
      </div>
    </LoadingStyled>
  </>
);

export default Loading;
